@import url('/src/utils/font.css');
@import url('/src/globalComponents/scrollbar.css');

/* reset css */

*,
*::after,
*::before {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Barlow' !important;
}

html {
    overflow-x: hidden;
    scroll-behavior: smooth;
}
