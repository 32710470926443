@font-face {
    font-family: 'Barlow';
    src: url(../assets/font/Barlow-Light.ttf);
    font-weight: 300;
}
@font-face {
    font-family: 'Barlow';
    src: url(../assets/font/Barlow-Medium.ttf);
    font-weight: 500;
}
@font-face {
    font-family: 'Barlow';
    src: url(../assets/font/Barlow-SemiBold.ttf);
    font-weight: 600;
}
@font-face {
    font-family: 'Barlow';
    src: url(../assets/font/Barlow-Bold.ttf);
    font-weight: 700;
}

@font-face {
    font-family: 'Outfit';
    src: url(../assets/font/outfit/Outfit-Bold.ttf);
    font-weight: 700;
}
@font-face {
    font-family: 'Outfit';
    src: url(../assets/font/outfit/Outfit-SemiBold.ttf);
    font-weight: 600;
}

@font-face {
    font-family: 'Outfit';
    src: url(../assets/font/outfit/Outfit-Medium.ttf);
    font-weight: 500;
}

@font-face {
    font-family: 'Outfit';
    src: url(../assets/font/outfit/Outfit-Regular.ttf);
    font-weight: 400;
}

@font-face {
    font-family: 'Outfit';
    src: url(../assets/font/outfit/Outfit-Light.ttf);
    font-weight: 300;
}
