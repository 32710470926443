::-webkit-scrollbar {
    width: 10px;
}

::-webkit-scrollbar-thumb {
    background-color: #fff;
    border-radius: 9999px;
    /* box-shadow: 1px 1px 1px 0px rgba(0, 0, 0, 0.098); */
    border: 1px solid #7b7b7b27;
}

::-webkit-scrollbar-track {
    background-color: #f6f6f6;

    border-radius: 10px;
}
